<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="编辑企业"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="right"
      >
        <el-form-item label-width="80px" label="企业名称" prop="companyName">
          <el-input
            v-model="formData.companyName"
            placeholder="请输入企业名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="企业类型" prop="companyType">
          <el-select
            v-model="formData.companyType"
            placeholder="请输入企业类型"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.companyTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="企业级别" prop="companyLevel">
          <el-select
            v-model="formData.companyLevel"
            placeholder="请输入企业级别"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.companyLevelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="企业地址" prop="companyAddress">
          <el-input
            v-model="formData.companyAddress"
            placeholder="请输入企业地址"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="区域名称" prop="areaName">
          <el-select
            v-model="formData.areaName"
            placeholder="请输入区域名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.areaNameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="联系人" prop="contact">
          <el-input
            v-model="formData.contact"
            placeholder="请输入联系人名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="手机号" prop="phone">
          <el-input
            v-model="formData.phone"
            placeholder="请输入联系人手机"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getAreaOptions } from "@/api/area";
import { getCompanyTypeOptions } from "@/api/company-type";
import { getCompanyLevelOptions } from "@/api/company-level";
import { editCompany } from "@/api/company";

export default {
  name: "CompanyEdit",
  components: {},
  props: {
    dialogVisible: Boolean,
    row: Object,
  },
  data() {
    return {
      visible: false,
      formData: {
        companyName: "",
        companyType: "",
        companyLevel: "",
        companyAddress: "",
        areaName: "",
        contact: "",
        phone: "",
      },
      options: {
        companyTypeOptions: [],
        companyLevelOptions: [],
        areaNameOptions: [],
      },
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        companyType: [
          {
            required: true,
            message: "请输入企业类型",
            trigger: "blur",
          },
        ],
        companyLevel: [
          {
            required: true,
            message: "请输入企业级别",
            trigger: "blur",
          },
        ],
        areaName: [
          {
            required: true,
            message: "请输入区域名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
    row: {
      handler(value) {
        this.formData = value;
      },
    },
  },
  created() {
    this.getOptions();
  },
  mounted() {
    this.visible = this.dialogVisible;
    this.formData = this.row;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        const requestBody = this.row;
        editCompany(requestBody).then(() => {
          this.$parent.getList();
        });
        this.close();
      });
    },
    // 获取区域名称
    // 获取企业类型
    // 获取企业级别
    getOptions() {
      getAreaOptions().then((result) => {
        this.options.areaNameOptions = result.data;
      });
      getCompanyTypeOptions().then((result) => {
        this.options.companyTypeOptions = result.data;
      });
      getCompanyLevelOptions().then((result) => {
        this.options.companyLevelOptions = result.data;
      });
    },
  },
};
</script>
<style></style>
