<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-chat-dot-round"></i> 信息管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>企业信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-input
            clearable
            size="mini"
            v-model="companyNameInput"
            placeholder="企业名"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
          <el-button
            @click="handleExport"
            plain
            type="warning"
            icon="el-icon-download"
            size="mini"
          >
            导出
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-button
          @click="handleAddCompany"
          type="primary"
          icon="el-icon-edit"
          size="mini"
        >
          添加企业
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="companyName" label="企业名称"> </el-table-column>
        <el-table-column prop="areaName" label="区域名称"> </el-table-column>
        <el-table-column prop="companyAddress" label="详细地址">
        </el-table-column>
        <el-table-column prop="contact" label="联系人"> </el-table-column>
        <el-table-column prop="phone" label="手机"> </el-table-column>
        <el-table-column prop="enable" label="状态">
          <template #default="scope">
            <el-switch
                v-model="scope.row.enable"
                @click="handleSwitch(scope.$index, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <company-add
      :dialog-visible="dialogVisibleCompanyAdd"
      @updateVisible="closeCompanyAdd"
    >
    </company-add>
    <company-edit
      :dialog-visible="dialogVisibleCompanyEdit"
      :row="editCompany"
      @updateVisible="closeCompanyEdit"
    >
    </company-edit>
  </div>
</template>

<script>
import CompanyAdd from "@/views/info/company/companyInfo/dialog/CompanyAdd";
import CompanyEdit from "@/views/info/company/companyInfo/dialog/CompanyEdit";
import { deleteCompany, getTable, switchStatus } from "@/api/company";
import { exportCompanyInfo } from "@/api/export";

export default {
  name: "CompanyInfo",
  components: { CompanyEdit, CompanyAdd },
  data() {
    return {
      companyNameInput: "",
      editCompany: {},
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisibleCompanyAdd: false,
      dialogVisibleCompanyEdit: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.companyNameInput = "";
    },
    handleSearch() {
      console.log(this.companyNameInput);
      this.getList();
    },
    handleExport() {
      exportCompanyInfo();
    },
    handleAddCompany() {
      this.dialogVisibleCompanyAdd = true;
    },
    closeCompanyAdd() {
      this.dialogVisibleCompanyAdd = false;
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.editCompany = JSON.parse(JSON.stringify(row));
      this.dialogVisibleCompanyEdit = true;
    },
    handleSwitch(index, row) {
      console.log(index, row);
      // 请求参数
      const params = {
        companyName: row.companyName,
        enable: row.enable === true ? 1 : 0,
      };
      switchStatus(params);
    },
    closeCompanyEdit() {
      this.dialogVisibleCompanyEdit = false;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.editCompany = row;
      this.$confirm(
        "此操作将永久删除该企业以企业下绑定的监控点, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          const params = { id: row.id };
          deleteCompany(params).then(() => {
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      const param = {
        companyName: this.companyNameInput,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        this.tableData = data.list;
        data.list.forEach((item) => {
          item.enable = item.enable === 1;
        });
      });
    },
  },
};
</script>

<style scoped></style>
